<template>
  <v-container
    ><SiteHeaderComponent :header="article.nr" />
    <div class="text-center grey--text text--darken-2 mb-12">
      <h3>Typ > Kategorie > Artikel</h3>
    </div>
    <div>
      <TimeFilter />
    </div>

    <v-card class="mx-auto mb-12">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          outlined
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="article.details"
        :items-per-page="45"
        :search="search"
        item-key="detail"
        class="elevation-1"
        :loading="summaryLoading"
        sort-by="description"
        :sort-desc="sortDesc"
        mobile-breakpoint="800"
      >
        <template v-slot:no-data>
          Keine Daten in diesem Zeitraum vorhanden.
        </template>
      </v-data-table>
    </v-card>

    <!-- <v-expansion-panels multiple>
      <v-expansion-panel v-for="(item, i) in 5" :key="i">
        <v-expansion-panel-header>
          Item
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

// Services
import addressService from '@/services/crm/addressService.js'
import distributorService from '@/services/statistics/distributorService.js'

// Components
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import TimeFilter from '@/components/statistics/TimeFilter.vue'

export default {
  components: {
    SiteHeaderComponent,
    TimeFilter
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    article: {
      nr: '',
      description: '',
      details: []
    },
    features: {},
    summaryLoading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    headers: [
      {
        text: 'Ausführung',
        align: 'start',
        value: 'detail'
      },
      {
        text: 'Anfangsbestand (Stk.)',
        value: 'openingStock',
        align: 'end'
      },
      {
        text: 'Einkäufe (Stk.)',
        value: 'purchases',
        align: 'end'
      },
      {
        text: 'Verkäufe (Stk.)',
        value: 'sales',
        align: 'end'
      },
      {
        text: 'Inventur (Stk.)',
        value: 'inventory',
        align: 'end'
      },
      {
        text: 'Materialverbrauch (Stk.)',
        value: 'materialsInput',
        align: 'end'
      },
      {
        text: 'Endbestand (Stk.)',
        value: 'finalStock',
        align: 'end'
      }
    ]
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    artNr: {
      type: String,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data
      })
      // no it doesn't
      this.loadArticleDetailed()
    },
    openArticleType() {
      console.log('test')
      // statisticsDistributorArticleCategories
      /*this.$router.push({
        name: 'statisticsDistributorArticleCategories',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: { lfdnr: this.lfdnr, articleType: item.code }
      })*/
    },
    loadArticleDetailed() {
      this.summaryLoading = true
      distributorService
        .getArticleDetailed(
          this.lfdnr,
          this.artNr,
          this.filterFromDate,
          this.filterToDate
        )
        .then(response => {
          this.article = response.data
          this.summaryLoading = false
        })

      distributorService
        .getArticleDetailedFeatures(
          this.lfdnr,
          this.artNr,
          this.filterFromDate,
          this.filterToDate
        )
        .then(response => {
          this.features = response.data
          this.summaryLoading = false
        })
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    })
  },
  mounted() {
    this.init()
  },
  watch: {
    filterFromDate: function() {
      this.loadArticleDetailed()
    },
    filterToDate: function() {
      this.loadArticleDetailed()
    }
  }
}
</script>

<style></style>
